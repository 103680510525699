/** @format */

import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Box } from "@mui/material";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Tag, Table, Space } from "antd";
import "./Bookings.css";
import {
	GetBookingRequestCount,
	GetUserBooking,
} from "../../services/Api/BookingApi";
import { Badge } from "antd";

const Bookings = () => {
	const navigate = useNavigate();
	const [bookingData, setBookingData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState("PENDING");
	const [nestedTab, setNestedTab] = useState("all");
	const [bookingRequestCount, setBookingRequestCount] = useState(0);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 20,
		},
		sortField: null,
		sortOrder: null,
	});
	console.log(nestedTab, "nestedTab");

	const getData = async (booking_status, period) => {
		try {
			setLoading(true);
			const result = await GetUserBooking(
				localStorage.getItem("adminToken"),
				booking_status,
				period // Pass the period parameter only if needed
			);
			const newData = result.data.data.map((item, index) => ({
				...item,
				index: index + 1,
			}));
			setBookingData(newData);
		} catch (e) {
			console.error(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
			} else {
				console.error("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	const fetchBookingRequestCount = async () => {
		try {
			const result = await GetBookingRequestCount(
				localStorage.getItem("adminToken")
			);
			const count = result.data.data.count;
			setBookingRequestCount(count > 0 ? count : null); // Set count or null
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getData(activeTab, nestedTab);
		fetchBookingRequestCount();
	}, [activeTab, nestedTab]);

	const navigateToViewBooking = (id) => {
		navigate(`/viewBooking/${id}`);
	};

	const navigateToEditBooking = (id) => {
		navigate(`/editBooking/${id}`);
	};

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const tabsContent = [
		{
			label: (
				<span>
					Booking Request{" "}
					{bookingRequestCount !== null && (
						<Badge count={bookingRequestCount} status="error" size="small" />
					)}
				</span>
			),
			key: "PENDING",
		},
		{ label: "Upcoming Bookings", key: "UPCOMING" },
		{ label: "Ongoing Bookings", key: "ONGOING" },
		{ label: "Booking History", key: "COMPLETED" },
		{ label: "Cancelled Bookings", key: "CANCELLED" },
	];

	const nestedTabsContent = {
		UPCOMING: [
			{ label: "All", key: "all" },
			{ label: "Today", key: "today" },
			{ label: "This Week", key: "current_week" },
			{ label: "This Month", key: "current_month" },
		],
		COMPLETED: [
			{ label: "All", key: "all" },
			{ label: "Past 30 Days", key: "past_30_days" },
			{ label: "Past 90 Days", key: "past_90_days" },
		],
		DELETED: [], // No nested tabs for DELETED
	};

	const columns = [
		{
			title: "S.No.",
			dataIndex: "index",
			sorter: (a, b) => a.index - b.index,
		},
		{
			title: "Booking Id",
			dataIndex: "booking_details_unique_id",
			key: "booking_unique_id",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (text) => text.split("T")[0],
		},
		{
			title: "Booked By",
			dataIndex: ["bookings", "booking_user", "user_profile", "name"],
			key: "name",
		},
		{
			title: "Employee Assigned",
			dataIndex: "service_provider_details",
			key: "employee_assigned",
			render: (serviceProviders) => {
				if (!serviceProviders || serviceProviders.length === 0) {
					return <span style={{ color: "red" }}>Not Assigned</span>;
				}
				const names = serviceProviders.map(
					(provider) => provider.service_provider_profile.user_profile.name
				);
				return <span>{names.join(", ")}</span>;
			},
		},
		{
			title: "Type",
			dataIndex: ["bookings", "type"],
			key: "type",
		},
		{
			title: "Booking Status",
			dataIndex: "booking_status",
			key: "status",
			render: (status) => {
				let color;
				let displayStatus = status;
				switch (status) {
					case "PENDING":
						color = "gold";
						break;
					case "SUCCESS":
						color = "green";
						displayStatus = "COMPLETED";
						break;
					case "ACCEPTED":
						color = "blue";
						break;
					case "REJECTED":
						color = "red";
						break;
					case "DELETED":
						color = "volcano";
						break;
					case "ONGOING":
						color = "purple";
						break;
					default:
						color = "black";
				}
				return (
					<Tag color={color} key={status}>
						{displayStatus}
					</Tag>
				);
			},
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<Space size="middle">
					<Button
						icon="pi pi-eye"
						rounded
						outlined
						severity="warning"
						style={{ borderRadius: "25px" }}
						onClick={() => navigateToViewBooking(record.id)}
					/>
					{(activeTab === "PENDING" || activeTab === "UPCOMING") && (
						<Button
							icon="pi pi-pencil"
							rounded
							outlined
							style={{ borderRadius: "25px" }}
							onClick={() => navigateToEditBooking(record.id)}
						/>
					)}
				</Space>
			),
		},
	];

	return (
		<Box>
			{/* Heading and tabs */}
			<div className="booking_container">
				<div>
					<h3 className="page-title">BOOKING MANAGEMENT</h3>
					<p className="page-sub-title">View User's Bookings</p>
				</div>
				<div className="tabs-container">
					<Tabs
						defaultActiveKey="all"
						onSelect={(key) => {
							setActiveTab(key);
							setNestedTab("all"); // Reset nested tab to "all" when main tab changes
						}}
						activeKey={activeTab}
					>
						{tabsContent.map((tab) => (
							<Tab eventKey={tab.key} title={tab.label} key={tab.key} />
						))}
					</Tabs>
				</div>
			</div>

			{/* Nested Tabs */}
			{nestedTabsContent[activeTab] &&
				nestedTabsContent[activeTab].length > 0 && (
					<div className="nested-tabs">
						<Tabs
							defaultActiveKey="all"
							onSelect={(key) => setNestedTab(key)}
							activeKey={nestedTab}
						>
							{nestedTabsContent[activeTab].map((tab) => (
								<Tab eventKey={tab.key} title={tab.label} key={tab.key} />
							))}
						</Tabs>
					</div>
				)}

			{/* Table component */}
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={bookingData}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
			/>
		</Box>
	);
};

export default Bookings;
